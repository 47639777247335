import { debounce } from 'lodash'
const INPUT_ACTIVE_CLASS = 'input-active'
const LABEL_SELECTOR = '.js-input-label'
const INPUT_SELECTOR = '.js-input'

class TextInput extends HTMLElement {
  constructor () {
    super ()

    this.labelEl = this.querySelector(LABEL_SELECTOR)
    this.inputEl = this.querySelector(INPUT_SELECTOR)

    setTimeout(() => {
      this.initChange()
    }, 100)

    this.inputEl.addEventListener('change', () => {
      this.initChange()
    })
  }

  initChange () {
    if (this.inputEl.value != '') {
      this.labelEl && this.labelEl.classList.add(INPUT_ACTIVE_CLASS)
    } else {
      this.labelEl && this.labelEl.classList.remove(INPUT_ACTIVE_CLASS)
    }
  }
}

customElements.define('text-input', TextInput)
